<template>
  <page icon="compress" :title="Naming.JobTypes" v-bind="{ loading }" :loading-text="`Loading ${Naming.JobTypes}…`">
    <div slot="tools">
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'job-type-create' }">
        <icon icon="plus"/>
        <span>{{ Naming.JobType }}</span>
      </router-link>
    </div>

    <div class="box is-paddingless">

      <form class="p-1">
        <text-input
            v-model="filters.search"
            :placeholder="`Search ${Naming.AssetGroups}`"
            classes="has-addons is-rounded">
          <template #right>
            <submit-button @submit="search" class="is-rounded" left-icon="search">
              Search
            </submit-button>
          </template>
        </text-input>
      </form>

      <table class="table is-fullwidth">
        <thead class="is-sticky">
        <tr>
          <th>Type</th>
          <th>Pass Percentage</th>
          <th>Active</th>
          <th>Requires Signature</th>
          <th>Single {{ Naming.Inspection }}</th>
          <th>{{ Naming.Jobs }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="type in jobTypes.data" :key="type.id">
          <td>
            <router-link class="is-block"
                         :to="{ name: 'job-type-edit', params: { 'jobType': type.id }}">
              <span>{{ type.description }}</span>
            </router-link>
          </td>
          <td>{{ type.pass_percentage + '%' }}</td>
          <td>
            <yes-no :value="type.active"/>
          </td>
          <td>
            <yes-no :value="type.requires_signature"/>
          </td>
          <td>
            <yes-no :value="type.single_inspection"/>
          </td>
          <td v-if="type.company_id !== null" >{{ type.jobs_count || 0 }}</td>
        </tr>
        </tbody>
      </table>
      <p class="has-text-centered" v-if="!jobTypes.data.length">
        No {{ Naming.JobTypes.toLowerCase() }} found
      </p>
      <div class="columns p-1">
        <div class="column">
          <pager class="pl" :pageable="jobTypes" @nav="goToPage" :context="Naming.JobType.toLowerCase()"/>
        </div>
        <div class="column is-narrow">
          <router-link
              class="button is-small is-rounded is-flex"
              :to="{
            name: 'deleted-job-type-index'
          }">
            <icon icon="trash"/>
            <span>Deleted {{ Naming.JobTypes }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
import {editJobType, newJobType} from '@/modals'

export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    }
  }),

  created() {
    this.loadJobTypes('/api/job-types')
  },

  methods: {
    loadJobTypes() {
      this.$scrollToTop()
      this.$store.dispatch('jobType/loadJobTypes', {
        path: 'api' + this.$route.fullPath,
        params: this.filters
      }).then(() => this.loading = false)
    },
    goToPage(page) {
      this.$scrollToTop()
      this.$router.push(page)
    },
    search() {
      this.$scrollToTop()
      this.$router.push({
        name: 'job-type-index',
        query: this.filters
      })
    },
  },

  computed: {
    ...mapGetters('jobType', [
      'jobTypes'
    ])
  },

  watch: {
    '$route': 'loadJobTypes'
  }

}
</script>
