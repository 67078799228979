<template>
  <div>
    <hr>
    <div class="columns">
      <div class="column">
        <text-input
            :value="field.label"
            @input="updateFieldLabel"
            :disabled="locked"
            placeholder="eg. VIN Number"
            hint="The label to show at the top of the field.">
          Field Label
        </text-input>
        <p class="help is-danger" v-if="!field.label.length">A Field Label is required.</p>
      </div>
      <div class="column">
        <text-input
            :value="field.placeholder"
            :disabled="locked"
            @input="updateFieldPlaceholder"
            hint="What should show inside the field when empty?">
          Placeholder
        </text-input>
      </div>
      <div class="column is-narrow">
        <checkbox-input
            classes="is-circle is-primary is-medium"
            :inner-label="field.is_required ? 'Yes' : 'No'"
            :disabled="locked"
            :hint="`Is the field required when closing the ${Naming.Job.toLowerCase()}?`"
            :value="field.is_required"
            @input="toggleRequiredField">
          Required?
        </checkbox-input>
      </div>
      <div class="column is-flex align-end is-narrow">
        <action-button
            @click="deleteField"
            :disabled="locked"
            class="is-rounded is-danger">
          <icon icon="trash"/>
        </action-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    field: {
      type: Object,
      default: () => ({
        field_id: '',
        label: '',
        is_required: false,
      }),
    },
    locked: {
      type: Boolean
    }
  },

  methods: {
    updateFieldLabel(value) {
      this.$store.commit('jobType/updateFieldLabel', {
        fieldId: this.field.field_id,
        value
      })
    },
    updateFieldPlaceholder(value) {
      this.$store.commit('jobType/updateFieldPlaceholder', {
        fieldId: this.field.field_id,
        value
      })
    },
    toggleRequiredField() {
      this.$store.commit('jobType/toggleRequiredField', this.field.field_id)
    },
    async deleteField() {
      if (await this.$confirm({
        title: 'Delete Field',
        message: this.Convert('Are you sure you want to remove this custom field?')
      })) {
        this.$store.commit('jobType/deleteField', this.field)
      }
    },
  },

  computed: {
    ...mapGetters('jobType', [
      'jobType'
    ]),
  },
};
</script>
