<template>
  <page icon="coins" :title="`Edit ${Naming.JobType}`" v-bind="{ loading }">
    <form class="box">
      <message v-if="isUniversalJobType" class="is-warning" title="Locked">
        This {{ Naming.JobType.toLowerCase() }} is a universal {{ Naming.JobType.toLowerCase() }} and cannot be edited.
        Please contact support if you would like to make changes to this {{ Naming.JobType.toLowerCase() }}.
      </message>
      <div class="columns is-multiline">
        <div class="column is-6">
          <text-input
              placeholder="ex: Repair, Survey, etc…"
              required
              :disabled="isUniversalJobType"
              :error="$root.errors.description"
              :value="jobType.description"
              @input="description">
            Description
          </text-input>
        </div>
        <div class="column is-6">
          <number-input
              :precision="0"
              :min="50"
              :max="100"
              :disabled="isUniversalJobType"
              required
              :error="$root.errors.pass_percentage"
              :value="jobType.pass_percentage"
              @input="passPercentage">
            Pass Percentage
          </number-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`${Naming.JobTypes} that are not active will not be available on ${Naming.Jobs}`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleActive"
              :value="jobType.active">
            Active {{ Naming.JobType }}
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`When required, ${Naming.Jobs.toLowerCase()} of this type will require the customer to provide their name as well as a signature before closing it off.`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleSignatureRequirement"
              :value="jobType.requires_signature">
            Requires a Signature
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`${Naming.Jobs} which carry this ${Naming.JobType.toLowerCase()} will be limited to 1 ${Naming.Inspection.toLowerCase()} per ${Naming.Job.toLowerCase()}.`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleSingleInspection"
              :value="jobType.single_inspection">
            Limit {{ Naming.Jobs }} to 1 {{ Naming.Inspection.toLowerCase() }} per {{ Naming.Job.toLowerCase() }}.
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`Display map (if available) on the ${Naming.Inspection.toLowerCase()} report PDF.`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleMapOnReport"
              :value="jobType.report_with_map">
            {{ Naming.Inspection }} Report - Show Map
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`Display ${Naming.CustomField.toLowerCase()} meta data for the ${Naming.Asset.toLowerCase()} on the PDF ${Naming.Inspection.toLowerCase()} report.`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleAssetMetaFieldsOnInspectionReport"
              :value="jobType.report_with_asset_meta">
            {{ Naming.Inspection }} Report - Show {{ Naming.Asset }} {{ Naming.CustomFields }}
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`Display ${Naming.CustomField.toLowerCase()} meta data for the ${Naming.Asset.toLowerCase()} on the PDF ${Naming.Job.toLowerCase()} report.`"
              classes="is-medium"
              :disabled="isUniversalJobType"
              @input="toggleAssetMetaFieldsOnJobReport"
              :value="jobType.job_report_with_asset_meta">
            {{ Naming.Job }} Report - Show {{ Naming.Asset }} {{ Naming.CustomFields }}
          </switch-input>
        </div>
        <div class="column is-6">
          <switch-input
              :description="`When required, ${Naming.Jobs.toLowerCase()} of this type will require the ${Naming.Technician.toLowerCase()} to only sign once at ${Naming.Job.toLowerCase()} closure which will then automatically apply the signature to all ${Naming.Inspections.toLowerCase()} (Warning: this will replace any previously captured ${Naming.Inspection.toLowerCase()} signatures).`"              classes="is-medium"
              @input="toggleTechnicianSignatureRequirement"
              :disabled="isUniversalJobType"
              :value="jobType.requires_technician_signature">
            Requires {{ Naming.Technician }} Signature
          </switch-input>
        </div>
      </div>
      <br/>

      <div class="columns">
        <div class="column">
          <h4 class="is-size-4 has-text-weight-light">{{ Naming.Job }} Closing {{ Naming.CustomFields }}</h4>
          <p class="has-text-cool-blue-text">When closing a {{ Naming.Job.toLowerCase() }} of this type, the fields you add below will be shown to the {{ Naming.Technician.toLowerCase() }} on closing the {{ Naming.Job.toLowerCase() }}, allowing you to store additional information related to the {{ Naming.Job.toLowerCase() }}.</p>
          <message class="is-warning mt">{{ Naming.CustomFields }} are considered fixed data points. These values will typically not change over time and are best suited for things like serial numbers etc. Changing these fields will have an effect on reporting</message>
        </div>
      </div>

      <div>
        <new-custom-field v-if="!isUniversalJobType"/>
        <custom-field
            v-for="(field, index) in jobType.closing_custom_fields"
            :field="field"
            :locked="isUniversalJobType"
            :key="index"/>
      </div>

      <div class="columns">
        <div class="column">
          <hr>
          <div class="buttons is-spaced mt-1">
            <submit-button :working="loading" :disabled="isUniversalJobType" @submit="save" class="is-success is-medium mt-1" left-icon="check">Update
              {{ Naming.JobType }}
            </submit-button>
            <action-button :disabled="jobType.jobs_count > 0 || isUniversalJobType" :working="loading" @click="deleteType" class="is-danger is-medium mt-1" left-icon="trash">Delete</action-button>
          </div>
        </div>
      </div>
      <br/>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import CustomField from "@/views/job-type/partials/CustomField.vue";
import NewCustomField from "@/views/job-type/partials/NewCustomField.vue";

export default {

  name: 'job-type-edit',

  components: {
    CustomField,
    NewCustomField
  },

  data: () => ({
    loading: true
  }),

  beforeDestroy() {
    this.clearJobType()
  },

  async created() {
    await this.$store
        .dispatch("jobType/loadJobType", this.$route.params.jobType)
        .then(() => {
          this.loading = false
        }).catch(error => {
          this.loading = false
          this.$whoops()
        });
  },

  methods: {
    ...mapMutations('jobType', [
      'description',
      'clearJobType',
      'passPercentage',
      'toggleActive',
      'toggleSignatureRequirement',
      'toggleSingleInspection',
      'toggleMapOnReport',
      'toggleAssetMetaFieldsOnInspectionReport',
      'toggleAssetMetaFieldsOnJobReport',
      'toggleTechnicianSignatureRequirement'
    ]),
    save() {
      this.loading = true
      this.$store.dispatch('jobType/save').then(jobType => {
        this.$toast.success(this.Convert(this.$lang.jobType.saved))
        this.loading = false
        this.$router.back()
      }).catch(error => {
        this.loading = false
        this.$whoops()
      })
    },
    async deleteType() {
      const { title, message, confirmText } = this.$lang.jobType.confirmDelete;
      const convertedTitle = await this.Convert(title)
      const convertedMessage = await this.Convert(message)
      const convertedConfirmText = await this.Convert(confirmText)
      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger'
      })) {
        this.$store.dispatch('jobType/delete').then(() => {
          this.working = false
          this.$toast.success(this.Convert(this.$lang.jobType.deleted))
          this.$router.back()
        }).catch(() => {
          this.working = false
          this.$whoops()
        })
      }
    },
  },

  computed: {
    ...mapGetters('jobType', [
      'jobType'
    ]),
    isUniversalJobType(){
      return this.jobType.report_layout?.length > 0
    }
  }
}
</script>
