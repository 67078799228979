<template>
<div>
  <div class="columns">
    <div class="column">
      <text-input
        v-model="field.label"
        placeholder="eg. VIN Number"
        hint="The label to show at the top of the field.">
        Field Label
      </text-input>
    </div>
    <div class="column">
      <text-input
        v-model="field.placeholder"
        hint="What should show inside the field when empty?">
        Placeholder
      </text-input>  
    </div>
    <div class="column is-narrow">
      <checkbox-input
        classes="is-circle is-primary is-medium"
        :inner-label="field.is_required ? 'Yes' : 'No'"
        hint="Is the field required when onboarding?"
        v-model="field.is_required">
        Required?
      </checkbox-input>  
    </div>
    <div class="column is-flex align-end is-narrow">
      <action-button
        :disabled="!field.label.length"
        @click="addCustomField"
        class="is-rounded is-success">
        <icon icon="plus" />
      </action-button>
    </div>
  </div>
</div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {

  data: () => ({
    field: {
      field_id: '',
      label: '',
      is_required: false,
    },
  }),

  created() {
    this.field.field_id = uuidv4()
  },

  methods: {
    addCustomField() {
      if (!this.field.label.length) {
        this.$toast.error('Please provide a field label.');
        return;
      }

      this.$store.commit('jobType/addClosingCustomField', this.field);

      this.field = {
        field_id: uuidv4(),
        label: '',
        is_required: false,
      }
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ])
  },
};
</script>
