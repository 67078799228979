<template>
<page icon="compress" :title="`Deleted ${Naming.JobTypes}`" v-bind="{ loading }" :loading-text="`Loading ${Naming.JobTypes}…`">
  <div class="box is-paddingless">

    <form class="p-1">
      <text-input
        v-model="filters.search"
        :placeholder="`Search ${Naming.AssetGroups}`"
        classes="has-addons is-rounded">
        <template #right>
          <submit-button @submit="search" class="is-rounded" left-icon="search">
            Search
          </submit-button>
        </template>
      </text-input>
    </form>

    <table class="table is-fullwidth">
      <thead class="is-sticky">
        <tr>
          <th>Type</th>
          <th class="is-narrow">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="type in jobTypes.data" :key="type.id">
          <td>
            {{ type.description }}
          </td>
          <td>
            <action-button
              :disabled="recovering"
              @click="recoverJobType(type)"
              class="is-small is-rounded">
              Recover
            </action-button>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!jobTypes.data.length">
      No {{ Naming.JobTypes.toLowerCase() }} found
    </p>
    <pager class="pl" :pageable="jobTypes" @nav="goToPage" :context="Naming.JobType.toLowerCase()"/>
  </div>
</page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true,
    recovering: false,
    filters: {
      search: ''
    }
  }),

  created() {
    this.loadJobTypes('/api/job-types')
  },

  methods: {
    loadJobTypes() {
      this.$scrollToTop()
      this.$store.dispatch('jobType/loadJobTypes', {
        path: 'api' + this.$route.fullPath,
        params: this.filters
      }).then(() => this.loading = false)
    },
    goToPage(page) {
      this.$scrollToTop()
      this.$router.push(page)
    },
    search() {
      this.$scrollToTop()
      this.$router.push({
        name: 'deleted-job-type-index',
        query: this.filters
      })
    },
    async recoverJobType(type) {
      if(await this.$confirm({
        title: this.Convert('Recover Job Type'),
        message: this.Convert('Are you sure you want to recover this job type?')
      })) {
        this.recovering = true
        this.$store.dispatch('jobType/recoverDeletedJobType', type.id).then(() => {
          this.$toast.success(this.Convert('Job Type Recovered'))
          this.$router.push({
            name: 'job-type-index'
          })
        }).catch(error => {
          this.recovering = false
          this.$whoops()
        })
      }
    }
  },

  computed: {
    ...mapGetters('jobType', [
      'jobTypes'
    ])
  },

  watch: {
    '$route': 'loadJobTypes'
  }

}
</script>
